import { h, Component, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../auth";
import Tooltip from "./Tooltip";
import Modal from "./Modal";
import { Link } from "preact-router/match";

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

const SortableItem = SortableElement((props) => {
  return (
    <li
      class="panel-block is-clickable"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div class="truncate-text">
        <span class="panel-icon" style={{ marginRight: "1.3em" }}>
          <i class="material-icons">list</i>
        </span>
        <span class="">
          <Tooltip label={props.value.label} className="custom-class" />
          ID: {props.sortIndex} {props.value.label}
        </span>
      </div>
      <div>
        <Link
          class="button is-small is-rounded"
          href={`/headers?id=${props.value.header_id}&s=${props.value.label}`}
          target="_blank"
        >
          <Tooltip label="Abrir cabecera" className="custom-class" />
          <span class="material-icons" style={{ fontSize: 12 }}>
            content_paste
          </span>
        </Link>
        <a
          onClick={props.onGrouped}
          class={`button ${
            props.value.grouped ? "is-link" : ""
          } is-small is-rounded`}
          style={{ marginLeft: ".5em", padding: "5px 8px" }}
        >
          <Tooltip label="Agrupar" className="custom-class" />
          <span class="material-icons" style={{ fontSize: 12 }}>
            groups
          </span>
        </a>
        <a
          onClick={props.onHidden}
          class={`button is-small is-rounded ${
            props.value.visible ? "" : "is-link"
          }`}
          style={{ marginLeft: ".5em", padding: "5px 8px" }}
        >
          <Tooltip label="Ocultar" className="custom-class" />
          <span class="material-icons" style={{ fontSize: 12 }}>
            visibility
          </span>
        </a>
        <a
          onClick={props.onDelete}
          class="button is-small is-rounded"
          style={{ marginLeft: ".5em", padding: "5px 8px" }}
        >
          <Tooltip label="Eliminar" className="custom-class" />
          <span style={{ fontSize: 12 }} class="material-icons">
            close
          </span>
        </a>
      </div>
    </li>
  );
});

const SortableList = SortableContainer(
  ({ items, onDelete, onGrouped, onHidden }) => {
    return (
      <ul class="panel">
        {items.length ? (
          items.map((value, index) => (
            <SortableItem
              key={`item-${value}`}
              index={index}
              value={value}
              sortIndex={index}
              onGrouped={() => onGrouped(index)}
              onHidden={() => onHidden(index)}
              onDelete={() => onDelete(index)}
            />
          ))
        ) : (
          <li class="panel-block">No hay cabeceras agregadas</li>
        )}
      </ul>
    );
  }
);

const SortableHeader = ({ value: items = [], onChange }) => {
  const [currentOption, setCurrentOption] = useState(null);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(items, oldIndex, newIndex).map((el, index) => ({
      ...el,
      order: index + 1,
    }));

    onChange(newItems);
  };

  const onDelete = (index) => {
    let newItems = items.filter((item, i) => i != index);

    onChange(newItems);
    // onChange(newItems.map((el, index) => ({ ...el, order: index + 1 })));
  };

  const onHidden = (index) => {
    let newItems = items;
    newItems[index].visible = !newItems[index].visible;
    // newItems[index].grouped = false;
    onChange(newItems);
  };

  const onGrouped = (index) => {
    let newItems = items;
    newItems[index].grouped = !newItems[index].grouped;

    newItems[index].visible = !newItems[index].grouped;

    onChange(newItems);
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/headers/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const addOption = () => {
    const item = {
      header_id: currentOption.value,
      label: currentOption.label,
      visible: true,
      grouped: false,
    };

    onChange(
      [...items, item].map((el, index) => ({ ...el, order: index + 1 }))
    );
    setCurrentOption(null);
  };

  return (
    <Fragment>
      <div className="columns">
        <div className="column is-6">
          <AsyncPaginate
            isClearable={true}
            loadOptions={loadOptions}
            additional={{
              page: 1,
            }}
            value={currentOption}
            onChange={setCurrentOption}
          />
        </div>
        <div className="column is-6 field is-grouped">
          <div className="control">
            <button
              disabled={!currentOption}
              onClick={addOption}
              type="button"
              class="button is-link"
            >
              Agregar
            </button>
          </div>
        </div>
      </div>

      <div
        style={{ maxHeight: "300px", overflowY: "auto", marginBottom: "20px" }}
      >
        <SortableList
          onDelete={onDelete}
          onGrouped={onGrouped}
          onHidden={onHidden}
          distance={1}
          items={items}
          onSortEnd={onSortEnd}
          helper="test"
        />
      </div>
    </Fragment>
  );
};

export default SortableHeader;
