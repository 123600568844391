import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import Translations from "../../components/Translations";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import { http as fetch } from "./../../auth";

const TagModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control } = useForm();
  const [data, setData] = useState("");
  const isAddMode = !id;

  useEffect(() => {
    reset();
    if (!isAddMode) {
      fetch("/api/tags/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          const fields = ["name", "icon", "translations", "order"];
          fields.forEach((field) => setValue(field, res[field]));
        });
    }
  }, [id]);

  const onSubmit = (data) => {
    const url = "";
    fetch(`/api/tags/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear etiqueta" : "Editar etiqueta"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Icon</label>
          <div class="control">
            <input
              {...register("icon")}
              class="input"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Orden</label>
          <div class="control">
            <input
              {...register("order", {
                valueAsNumber: true
              })}
              class="input"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="field">
          <label class="label">Traducciones</label>
          <div className="control">
            <Controller
              name="translations"
              control={control}
              rules={{ required: false }}
              render={({ field }) => <Translations {...field} />}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TagModal;
