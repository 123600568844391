import { h, Component } from "preact";
import classnames from "classnames";

const Modal = (props) => {
  const {
    active,
    title,
    onClose: _,
    class: klass,
    children,
    actions = true,
  } = props;
  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <div {...props} class={classnames("modal", { "is-active": active }, klass)}>
      <div class="modal-background"></div>
      <div class="modal-card" style={props.customStyle}>
        <header class="modal-card-head">
          <p class="modal-card-title">{title}</p>
          <a class="delete" aria-label="close" onClick={handleClose}></a>
        </header>
        <section class="modal-card-body">{children}</section>
        <footer class="modal-card-foot">
          {actions && (
            <>
              <button form="form" type="submit" class="button is-success">
                Guardar
              </button>
              <button class="button" onClick={handleClose}>
                Cancelar
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  );
};

export default Modal;
