import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../../auth";

const defaultValues = {
  lang: "",
  original: "",
  value: "",
};

const WordModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control } =
    useForm();
  const [data, setData] = useState("");
  const [languagesRecord, setLanguages] = useState([]);
  const isAddMode = !id;

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/languages/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.key,
      label: el.name,
    }));

    setLanguages(options);
    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    reset(defaultValues);
    if (!isAddMode) {
      fetch("/api/words/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          reset(res);
        });
    }
  }, [id, activeModal]);

  const onSubmit = (data) => {
    const url = "";
    fetch(`/api/words/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear palabra" : "Editar palabra"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label class="label">Idioma</label>
          <div class="control">
            <Controller
              name="lang"
              control={control}
              render={({ field }) => (
                <AsyncPaginate
                  inputRef={field.ref}
                  isClearable={true}
                  loadOptions={loadOptions}
                  defaultOptions
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  value={
                    languagesRecord.find((c) => c.value === field.value) || null
                  }
                  onChange={(val) => field.onChange(val?.value)}
                />
              )}
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Palabra</label>
          <div class="control">
            <input
              {...register("original")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Traduccion</label>
          <div class="control">
            <input
              {...register("value")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default WordModal;
