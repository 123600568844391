import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useForm, Controller } from "react-hook-form";
import { route } from "preact-router";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import Select from "react-select";
import Layout from "../../layouts/Layout";
import SortableHeader from "../../components/SortableHeader";
import FilterSelector from "../../components/FilterSelector";
import Translations from "../../components/Translations";
import Tooltip from "../../components/Tooltip";
import Modal from "../../components/Modal";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../../auth";

const allowedFormats = [
  { label: "PDF", value: "pdf" },
  { label: "CSV", value: "csv" },
  { label: "XLSX", value: "xlsx" },
  { label: "TXT", value: "txt" },
];

const typeOptions = [
  { label: "Common", value: "common" },
  { label: "Tiempo y Asistencia", value: "tyasist" },
  { label: "Acceso", value: "acceso" },
  { label: "Visitantes", value: "visitantes" },
];

const asciiCharsOptions = [
  { label: "Nada", value: "0" },
  { label: ",", value: "44" },
  { label: ";", value: "59" },
  { label: "Tabulacion", value: "9" },
];

const yesnoOptions = [
  { label: "Si", value: true },
  { label: "No", value: false },
];

const orientationPdfOptions = [
  { label: "Vertical", value: "vertical" },
  { label: "Horizontal", value: "horizontal" },
];

const srcCodePython = `def _clean_data(session, cleaned_data, form):
    cleaned = cleaned_data.copy()
    return cleaned
`;

const srcTitlePython = `async def _clean_data(session, cleaned_data, form):
    cleaned = cleaned_data.copy()
    # await t(session, form.lang, "since", "01-04-2022", "01-04-2022")
    # // Returns since/desde 01-04-2022 to 01-04-2022
    cleaned['report_title'] = "Titulo personalizado"
    return cleaned
`;

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const CreateReportPage = ({ id }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      has_func_callable: false,
      db_master: false,
      func_str: srcCodePython,
    },
  });
  const [clientsRecord, setClients] = useState([]);
  const [modulesRecord, setModules] = useState([]);
  const [titleGroupedModal, setTitleGroupedModal] = useState(false);
  const [footerTitleModal, setFooterTitleModal] = useState(false);

  const [customTitleRangeModal, setCustomTitleRangeModal] = useState(false);
  const [functionModal, setFunctionModal] = useState(false);
  const {
    has_func_callable = false,
    allowed_formats = [],
    headers = [],
  } = watch();

  const isAddMode = !id;


  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/clients/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id_client,
      label: el.client,
    }));

    setClients((prev) => [...prev, ...options]);

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsTags = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/subtags/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsComponents = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/components/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsParentReports = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/parent-reports/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const addTag = (field, name, key) => {
    const newTagName = toTitleCase(name.replaceAll("_", " ")).replaceAll(
      " ",
      ""
    );
    field.onChange(`${field.value || ""} {{ ${newTagName}.${key} }}`);
  };

  const loadOptionsModules = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/modules/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    setModules(options);
    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (data) => {
    data.group_by = data.headers
      ?.filter((el) => el.grouped)
      .map((el) => el.header_id);

    data.subtags = data.subtags?.length ? data.subtags.map(i => i.value) : []
    // data.parent_report = data.parents?.length ? data.parent_report.value : null
    data.parent_report = data.parent_report?.value
    data.main_filter_id = data.main_filter_id?.value

    fetch(`/api/reports/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        if (id) {
          window.location = "/reports/" + id;
        } else {
          route("/", true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isAddMode) {
      fetch("/api/reports/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          let data = {
            ...res,
          };

          data.headers = data.headers.map((el) => ({
            order: el.order,
            id: el.id,
            header_id: el.header.id,
            label: el.header.name,
            visible: el.visible,
            grouped: data.group_by?.includes(el.header.id),
          }));

          data.secondary_filters = data.secondary_filters.map(el => ({
            value: el.id,
            label: el.name
          }))

          data.filters = data.filters.map((el) => ({
            id: el.id,
            filter_id: el.filter_id,
            action: el.action,
            label: `ID: ${el.filter_id} ${el.filter.parameter_name}`,
          }));

          data.subtags = data.subtags.map(el => ({
            value: el.id,
            label: el.name
          }))

          data.parent_report = data.parents.length ? { label: data.parents[0].name, value: data.parents[0].id } : null
          data.main_filter_id = !!data.main_filter_id ? { label: data.main_filter.name, value: data.main_filter.id } : null

          setClients((prev) => [
            ...prev,
            ...data.clients_allowed.map((el) => ({
              label: el.name,
              value: el.client_pk,
            })),
            ...data.clients_not_allowed.map((el) => ({
              label: el.name,
              value: el.client_pk,
            })),
          ]);

          setClients((prev) =>
            prev.filter(
              (value, index, self) =>
                self.map((x) => x.value).indexOf(value.value) == index
            )
          );

          data.clients_allowed = data.clients_allowed.map((el) => el.client_pk);
          data.clients_not_allowed = data.clients_not_allowed.map(
            (el) => el.client_pk
          );

          reset(data);
        });
    }
  }, []);

  return (
    <Layout breadcrumbs={["Reportes", "Crear"]}>
      <form onSubmit={handleSubmit(onSubmit)} class="columns">
        <div className="column is-7">
          <div class="card events-card" style={{ marginBottom: "2rem" }}>
            <header class="card-header">
              <p class="card-header-title">Reporte</p>
            </header>
            <div className="card-content">
              <div class="field">
                <label class="label">Nombre</label>
                <div class="control">
                  <input
                    {...register("name")}
                    class="input"
                    type="text"
                    placeholder=""
                    required
                  />
                </div>
                {errors.name && errors.name.type === "required" && (
                  <span class="help is-danger">This is required</span>
                )}
              </div>
              <div class="field">
                <label class="label">Tabla SQL</label>
                <div class="control">
                  <textarea
                    {...register("table_sql")}
                    class="textarea"
                    placeholder="e.g. Hello world"
                  ></textarea>
                </div>
                {errors.table_sql && errors.table_sql.type === "required" && (
                  <span class="help is-danger">This is required</span>
                )}
              </div>
              <div class="field">
                <label class="label">Complemento</label>
                <div class="control">
                  <textarea
                    {...register("complement")}
                    class="textarea"
                    placeholder=""
                  ></textarea>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Formatos permitidos</label>
                    <div class="control">
                      <Controller
                        name="allowed_formats"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <Select
                            isMulti
                            options={allowedFormats}
                            value={allowedFormats.filter((c) =>
                              field.value?.includes(c.value)
                            )}
                            onChange={(val) => {
                              field.onChange(val?.map((el) => el.value));
                            }}
                          />
                        )}
                      />
                    </div>
                    {errors.allowed_formats &&
                      errors.allowed_formats.type === "required" && (
                        <span class="help is-danger">This is required</span>
                      )}
                  </div>
                </div>
                <div className="column is-6">
                  <div class="field">
                    <label class="label">DB Master</label>
                    <div class="control">
                      <Controller
                        name="db_master"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        defaultValue={false}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Separador TXT</label>
                    <div class="control">
                      <Controller
                        name="separator_txt"
                        control={control}
                        rules={{
                          required: allowed_formats?.includes("txt"),
                        }}
                        render={({ field }) => (
                          <Select
                            isDisabled={!allowed_formats?.includes("txt")}
                            options={asciiCharsOptions}
                            value={asciiCharsOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Orientación del PDF</label>
                    <div class="control">
                      <Controller
                        name="orientation_pdf"
                        control={control}
                        rules={{
                          required: allowed_formats?.includes("pdf"),
                        }}
                        render={({ field }) => (
                          <Select
                            isDisabled={!allowed_formats?.includes("pdf")}
                            options={orientationPdfOptions}
                            value={orientationPdfOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                      {errors.orientation_pdf &&
                        errors.orientation_pdf.type === "required" && (
                          <span class="help is-danger">This is required</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Enumerar filas</label>
                    <div class="control">
                      <Controller
                        name="enumerate_rows"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        defaultValue={false}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Tipo de modulo</label>
                    <div className="control">
                      <Controller
                        name="modulesreport_id"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            defaultOptions
                            isClearable={true}
                            loadOptions={loadOptionsModules}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            value={
                              modulesRecord.find(
                                (c) => c.value === field.value
                              ) || null
                            }
                            onChange={(val) => field.onChange(val?.value)}
                          />
                        )}
                      />
                      {/* <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            value={typeOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                            options={typeOptions}
                          />
                        )}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Funcion personalizada</label>
                    <div class="control">
                      <Controller
                        name="has_func_callable"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Funcion personalizada</label>
                    <div className="control">
                      <a
                        class="button is-link is-small"
                        onClick={() => setFunctionModal(true)}
                      >
                        Editar funcion personalizada
                      </a>
                    </div>
                  </div>

                  <Modal
                    title="Editando funcion personalizada"
                    active={functionModal}
                    onClose={() => setFunctionModal(false)}
                    actions={false}
                    customStyle={{
                      width: "940px",
                    }}
                  >
                    <Controller
                      name="func_str"
                      control={control}
                      defaultValue={srcCodePython}
                      render={({ field }) => (
                        <>
                          <CodeMirror
                            editable={has_func_callable}
                            height="400px"
                            value={field.value}
                            extensions={[python()]}
                            onChange={(value, viewUpdate) => {
                              field.onChange(value);
                            }}
                          />
                          <div class="tags field">
                            <span
                              onClick={() => {
                                field.onChange(srcTitlePython);
                              }}
                              className="tag is-link"
                            >
                              Funcion con titulo personalizado
                            </span>
                          </div>
                        </>
                      )}
                    />
                  </Modal>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label class="label">Clientes permitidos</label>
                    <div className="control">
                      <Controller
                        name="clients_allowed"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={true}
                            isClearable={true}
                            defaultOptions
                            placeholder="Todos"
                            loadOptions={loadOptions}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            value={clientsRecord
                              .filter(
                                (value, index, self) =>
                                  self
                                    .map((x) => x.value)
                                    .indexOf(value.value) == index
                              )
                              .filter((c) => {
                                return field.value?.includes(c.value);
                              })}
                            onChange={(val) => {
                              if (val == null) {
                                field.onChange([]);
                              } else {
                                field.onChange(val?.map((el) => el.value));
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label class="label">Ocultar a clientes</label>
                    <div className="control">
                      <Controller
                        name="clients_not_allowed"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={true}
                            isClearable={true}
                            defaultOptions
                            placeholder="Ninguno"
                            loadOptions={loadOptions}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            value={clientsRecord
                              .filter(
                                (value, index, self) =>
                                  self
                                    .map((x) => x.value)
                                    .indexOf(value.value) == index
                              )
                              .filter((c) => {
                                return field.value?.includes(c.value);
                              })}
                            onChange={(val) => {
                              if (val == null) {
                                field.onChange([]);
                              } else {
                                field.onChange(val?.map((el) => el.value));
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Antiguo ID</label>
                    <div class="control">
                      <input
                        {...register("old_id", {
                          valueAsNumber: true,
                        })}
                        class="input"
                        type="number"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Total de sumas</label>
                    <div class="control">
                      <Controller
                        name="has_total_sums"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        defaultValue={false}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Fuente monoespaceada</label>
                    <div class="control">
                      <Controller
                        name="has_monospaced_font"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        defaultValue={false}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Activo</label>
                    <div class="control">
                      <Controller
                        name="active"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        defaultValue={true}
                        render={({ field }) => (
                          <Select
                            options={yesnoOptions}
                            value={yesnoOptions.find(
                              (c) => c.value === field.value
                            )}
                            onChange={(val) => field.onChange(val.value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Tipos de etiqueta</label>
                    <div className="control">
                      <Controller
                        name="subtags"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={true}
                            isClearable={true}
                            defaultOptions
                            placeholder="Todos"
                            loadOptions={loadOptionsTags}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Padre de reporte</label>
                    <div className="control">
                      <Controller
                        name="parent_report"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={false}
                            isClearable={true}
                            defaultOptions
                            placeholder="Seleccione"
                            loadOptions={loadOptionsParentReports}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Filtro principal</label>
                    <div className="control">
                      <Controller
                        name="main_filter_id"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={false}
                            isClearable={true}
                            defaultOptions
                            placeholder="Seleccione"
                            loadOptions={loadOptionsComponents}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label class="label">Filtro secundarios</label>
                    <div className="control">
                      <Controller
                        name="secondary_filters"
                        control={control}
                        render={({ field }) => (
                          <AsyncPaginate
                            inputRef={field.ref}
                            isMulti={true}
                            isClearable={true}
                            defaultOptions
                            placeholder="Seleccione"
                            loadOptions={loadOptionsComponents}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div class="field">
                    <label class="label">Orden</label>
                    <div class="control">
                      <input
                        {...register("order", {
                          valueAsNumber: true
                        })}
                        class="input"
                        type="number"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field columns">
                <div className="column">
                  <button type="submit" class="button is-link">
                    Guardar
                  </button>
                </div>
                <div className="column">
                  <button
                    class="button is-link p10"
                    onClick={() => {
                      route("/", true);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div class="card events-card" style={{ marginBottom: "2rem" }}>
            <header class="card-header">
              <p class="card-header-title">Cabeceras</p>
            </header>
            <div className="card-content">
              <Controller
                name="headers"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={[]}
                render={({ field }) => (
                  <div>
                    <SortableHeader
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
              {errors.headers && errors.headers.type === "required" && (
                <span class="help is-danger">This is required</span>
              )}
              <div className="control field">
                <Controller
                  name="total_custom_title"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Modal
                      title="Editando titiulo de total de rango"
                      active={customTitleRangeModal}
                      actions={false}
                      onClose={() => setCustomTitleRangeModal(false)}
                    >
                      <div className="columns is-multiline">
                        <div className="column">
                          <textarea
                            class="textarea"
                            placeholder="e.g. Hello world"
                            value={field.value}
                            onChange={field.onChange}
                          ></textarea>
                        </div>
                        <div className="column is-12">
                          <div class="tags">
                            <span
                              onClick={() => {
                                field.onChange(field.value + `{{ "key"|t }}`);
                              }}
                              class="tag is-primary"
                            >
                              Insertar traduccion
                            </span>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}
                />
                <Controller
                  name="custom_group_title"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Modal
                      title="Editando titulo de cabecera"
                      active={titleGroupedModal}
                      actions={false}
                      onClose={() => setTitleGroupedModal(false)}
                    >
                      <div className="columns is-multiline">
                        <div className="column">
                          <textarea
                            class="textarea"
                            placeholder="e.g. Hello world"
                            value={field.value}
                            onChange={field.onChange}
                          ></textarea>
                        </div>
                        <div className="column is-12">
                          <div class="tags">
                            <span
                              onClick={() => {
                                field.onChange(field.value + "//t");
                              }}
                              class="tag is-primary"
                            >
                              Nueva columna
                            </span>
                            <span
                              onClick={() => {
                                field.onChange(field.value + `{{ "key"|t }}`);
                              }}
                              class="tag is-primary"
                            >
                              Insertar traduccion
                            </span>
                            {headers.map((el) => (
                              <>
                                <span
                                  class="tag is-link"
                                  onClick={() =>
                                    addTag(field, el.label, "label")
                                  }
                                >
                                  {el.label}.Label
                                </span>
                                <span
                                  class="tag is-warning"
                                  onClick={() =>
                                    addTag(field, el.label, "value")
                                  }
                                >
                                  {el.label}.Value
                                </span>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}
                />
                <Controller
                  name="footer_custom_title"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Modal
                      title="Editando titulo de pie de pagina"
                      active={footerTitleModal}
                      actions={false}
                      onClose={() => setFooterTitleModal(false)}
                    >
                      <div className="columns is-multiline">
                        <div className="column">
                          <textarea
                            class="textarea"
                            placeholder="e.g. Hello world"
                            value={field.value}
                            onChange={field.onChange}
                          ></textarea>
                        </div>
                        <div className="column is-12">
                          <div class="tags">
                            <span
                              onClick={() => {
                                field.onChange(field.value + `{{ "key"|t }}`);
                              }}
                              class="tag is-primary"
                            >
                              Insertar traduccion
                            </span>
                            {headers
                              .filter((el) => el.grouped)
                              .map((el) => (
                                <>
                                  <span
                                    class="tag is-link"
                                    onClick={() =>
                                      addTag(field, el.label, "label")
                                    }
                                  >
                                    {el.label}.Label
                                  </span>
                                  <span
                                    class="tag is-warning"
                                    onClick={() =>
                                      addTag(field, el.label, "value")
                                    }
                                  >
                                    {el.label}.Value
                                  </span>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}
                />
                <div className="buttons">
                  <a
                    onClick={() => {
                      if (headers.some((item) => item.grouped)) {
                        setTitleGroupedModal((prev) => !prev);
                      }
                    }}
                    class="button is-link"
                    disabled={!headers.some((item) => item.grouped)}
                  >
                    Titulo de cabecera
                    <Tooltip label="Editar titulo de las columnas agrupadas" />
                  </a>

                  <a
                    onClick={() => setFooterTitleModal(true)}
                    class="button is-link"
                  >
                    Titulo de pie de pagina
                  </a>
                  <a
                    onClick={() => setCustomTitleRangeModal(true)}
                    class="button is-link"
                  >
                    Titulo de total del rango
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card events-card" style={{ marginBottom: "2rem" }}>
            <header class="card-header">
              <p class="card-header-title">Filtros</p>
            </header>
            <div className="card-content">
              <Controller
                name="filters"
                control={control}
                render={({ field }) => (
                  <FilterSelector
                    value={field.value}
                    onChange={(val) => {
                      field.onChange(val);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div class="card events-card" style={{ marginBottom: "2rem" }}>
            <header class="card-header">
              <p class="card-header-title">Traducciones</p>
            </header>
            <div className="card-content">
              <Controller
                name="translations"
                control={control}
                render={({ field }) => <Translations {...field} />}
              />
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default CreateReportPage;
