import { h, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../auth";

const FilterItem = ({ value, onDelete, onChangeAction }) => {
  return (
    <div class="field has-addons">
      <p class="control">
        <span class="select">
          <select
            value={value.action}
            onChange={(ev) => {
              onChangeAction(ev.target.value);
            }}
          >
            <option value="default">Por defecto</option>
            <option value="required">Requerido</option>
            <option value="required_actived">Requerido Activo</option>
            <option value="required_main_actived">Requerido Principal</option>
            <option value="sent">En request</option>
            <option value="sent_main">En request Principal</option>
            <option value="main">Principal</option>
          </select>
        </span>
      </p>
      <p class="control is-expanded">
        <input class="input" type="text" readonly value={value.label} />
      </p>
      <p class="control">
        <a onClick={onDelete} class="button is-danger">
          <span class="material-icons">delete</span>
        </a>
      </p>
    </div>
  );
};

const FilterSelector = ({ value: items = [], onChange }) => {
  const [currentOption, setCurrentOption] = useState(null);
  // const [items, setItems] = useState([]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/filters/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.parameter_name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const addOption = () => {
    let item = {
      filter_id: currentOption.value,
      label: currentOption.label,
      action: "sent",
    };
    const isFound = items.some((el) => el.filter_id === item.filter_id);
    if (!isFound) {
      onChange([...items, item]);
      // setItems((items) => [...items, item]);
      setCurrentOption(null);
    }
  };

  const handleChangeAction = (index, val) => {
    const copyItems = items.slice();
    copyItems[index].action = val;
    onChange(copyItems);
  };

  const handleDelete = (index) => {
    onChange(items.filter((item, i) => i != index));
  };

  return (
    <>
      <div class="columns">
        <div className="column is-6">
          <AsyncPaginate
            isClearable={true}
            loadOptions={loadOptions}
            additional={{
              page: 1,
            }}
            value={currentOption}
            onChange={setCurrentOption}
          />
        </div>
        <div className="column is-6">
          <button
            disabled={!currentOption}
            type="button"
            class="button is-link"
            onClick={addOption}
          >
            Agregar
          </button>
        </div>
      </div>
      {items.map((item, index) => (
        <FilterItem
          value={item}
          index={index}
          onChangeAction={(val) => handleChangeAction(index, val)}
          onDelete={() => handleDelete(index)}
        />
      ))}
    </>
  );
};

export default FilterSelector;
