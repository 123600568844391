import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Modal from "../../components/Modal";
import Translations from "../../components/Translations";
import { http as fetch } from "./../../auth";

const options = [
  { label: "int", value: "int" },
  { label: "string", value: "string" },
  { label: "date", value: "date" },
  { label: "datetime", value: "datetime" },
  { label: "time", value: "time" },
  { label: "fulltime", value: "fulltime" },
  { label: "float", value: "float" },
  { label: "temperatura", value: "temperature" },
  { label: "Unidades de conversion Km/Mi", value: "units_of_length" },
  { label: "Horas calculadas", value: "estimated_time" },
];

const yesnoOptions = [
  { label: "Si", value: true },
  { label: "No", value: false },
];

const HeaderModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control, watch } =
    useForm({
      defaultValues: {
        display_type: "string",
      },
    });
  const [data, setData] = useState("");
  const [langs, setLanguages] = useState([]);
  const isAddMode = !id;

  const display_type = watch("display_type");

  window.reset = reset;

  useEffect(() => {
    reset();
    if (!isAddMode) {
      fetch("/api/headers/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          const fields = [
            "name",
            "value",
            "display_type",
            "translations",
            "countable",
            "truncable_text",
            "truncable_text_length",
            "width_pdf",
            "custom_template",
          ];
          fields.forEach((field) => setValue(field, res[field]));
        });
    }
  }, [id, activeModal]);

  const onSubmit = (data) => {
    fetch(`/api/headers/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear cabecera" : "Editar cabecera"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">SQL</label>
          <div class="control">
            <input
              {...register("value")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-6">
            <div class="field">
              <label class="label">Tipo de datos</label>
              <div className="control">
                <Controller
                  name="display_type"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      options={options}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      value={options.find((c) => c.value === field.value)}
                      onChange={(val) => field.onChange(val.value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label class="label">Ancho de columna en PDF</label>
              <div className="control">
                <input
                  {...register("width_pdf")}
                  class="input"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-6">
            <div className="field">
              <label class="label">Permitir truncar el texto</label>
              <div className="control">
                <Controller
                  name="truncable_text"
                  defaultValue={false}
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <Select
                      options={yesnoOptions}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      value={yesnoOptions.find((c) => c.value === field.value)}
                      onChange={(val) => field.onChange(val.value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label class="label">Tamaño permitido para truncar</label>
              <div className="control">
                <input
                  {...register("truncable_text_length")}
                  class="input"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Sumar columna</label>
          <div className="control">
            <Controller
              name="countable"
              defaultValue={false}
              control={control}
              rules={{}}
              render={({ field }) => (
                <Select
                  isDisabled={
                    !["int", "float", "estimated_time"].includes(display_type)
                  }
                  options={yesnoOptions}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  value={yesnoOptions.find((c) => c.value === field.value)}
                  onChange={(val) => field.onChange(val.value)}
                />
              )}
            />
          </div>
        </div>

        <div className="field">
          <label class="label">Plantilla</label>
          <div className="control">
            <textarea
              {...register("custom_template")}
              class="textarea"
              placeholder="e.g. Hello world"
            ></textarea>
          </div>
        </div>
        <div className="field">
          <label class="label">Traducciones</label>
          <div className="control">
            <Controller
              name="translations"
              control={control}
              rules={{ required: false }}
              render={({ field }) => <Translations {...field} />}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default HeaderModal;
