import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useDebounce } from "../../hooks";
import Tooltip from "../../components/Tooltip";
import Layout from "../../layouts/Layout";
import Paginator from "../../components/Paginator";
import SubTagModal from "./SubTagModal";
import { http as fetch } from "./../../auth";

const SubTags = () => {
  const [data, setData] = useState({
    records: [],
    total: 0,
    per_page: 20,
  });
  const [query, setQuery] = useState("");
  const [page, setCurrentPage] = useState(1);
  const [activeModal, setActiveModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const debouncedSearchTerm = useDebounce(query, 500);

  const handleClose = () => {
    setActiveModal(false);
    setActiveId(null);
  };


  const handleDelete = (id) => {
    let result = confirm("¿Está seguro de querer eliminar este objeto? ");
    if (result) {
      fetch("/api/subtags/" + id, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
            fetchData();
        });
    }
  };

  const fetchData = () => {
    fetch(`/api/subtags/?page=${page}&q=${debouncedSearchTerm}`)
      .then((resp) => {
        if (resp.ok) return resp.json();
        throw new Error("something went wrong while requesting posts");
      })
      .then((data) => setData(data));
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <Layout
      breadcrumbs={["Etiquetas"]}
      searchBar={(e) => setQuery(e.target.value)}
    >
      <SubTagModal
        activeModal={activeModal}
        handleClose={handleClose}
        reloadPage={fetchData}
        id={activeId}
      />
      <div className="columns is-multiline" style={{ margin: "1rem 0" }}>
        <div class="column is-12">
          <div class="card events-card">
            <header class="card-header">
              <p class="card-header-title">Etiquetas</p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-table">
              <div class="content">
                <table class="table is-fullwidth is-striped">
                  <tbody>
                    {data.records.length ? (
                      data.records.map((item) => (
                        <tr key={item.id}>
                          <td width="5%"></td>
                          <td>{item.name}</td>
                          <td>{item.tag?.name}</td>
                          <td class="level-right actions">
                            <a
                              class="button is-small is-link"
                              onClick={() => {
                                setActiveId(item.id);
                                setActiveModal(true);
                              }}
                            >
                              <Tooltip
                                label="Editar"
                                className="custom-class"
                              />
                              <span class="material-icons">edit_note</span>
                            </a>
                            <a
                              class="button is-small is-danger"
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                            >
                              <Tooltip
                                label="Eliminar"
                                className="custom-class"
                              />
                              <span class="material-icons">delete</span>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style="text-align: center;">
                        No hay datos para mostrar
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <a onClick={() => setActiveModal(true)} class="card-footer-item">
                Crear tipo etiqueta
              </a>
            </footer>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Paginas</p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="">
                <Paginator
                  total={data.total}
                  page={page}
                  setCurrentPage={setCurrentPage}
                  pageSize={data.per_page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubTags;
