import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import Translations from "../../components/Translations";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import { http as fetch } from "./../../auth";
import { AsyncPaginate } from "react-select-async-paginate";


const SubTagModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control } = useForm();
  const [data, setData] = useState("");
  const isAddMode = !id;

  useEffect(() => {
    reset({
      name: '',
      tag_id: ''
    });
    if (!isAddMode) {
      fetch("/api/subtags/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          const fields = ["name", "icon", "translations", "order"];
          fields.forEach((field) => setValue(field, res[field]));
          if (res.tag_id) {
            fetch("/api/tags/" + res.tag_id)
              .then((res) => {
                if (res.ok) return res.json();
                return Promise.reject(res);
              })
              .then((res) => {
                // setComponents((prev) => [{ label: res.name, value: res.id }]);
                setValue("tag_id", { label: res.name, value: res.id });
              });
          }
        });
    }
  }, [id]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/tags/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (data) => {
    const url = "";
    data.tag_id = data.tag_id?.value
      ? data.tag_id.value
      : null;
    fetch(`/api/subtags/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear etiqueta" : "Editar etiqueta"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Icon</label>
          <div class="control">
            <input
              {...register("icon")}
              class="input"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="field">
          <label class="label">Etiqueta</label>
          <div class="control">
            <Controller
              name="tag_id"
              control={control}
              render={({ field }) => (
                <AsyncPaginate
                  inputRef={field.ref}
                  isClearable={true}
                  loadOptions={loadOptions}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Orden</label>
          <div class="control">
            <input
              {...register("order", {
                valueAsNumber: true
              })}
              class="input"
              type="number"
              placeholder=""
            />
          </div>
        </div>
        <div className="field">
          <label class="label">Traducciones</label>
          <div className="control">
            <Controller
              name="translations"
              control={control}
              rules={{ required: false }}
              render={({ field }) => <Translations {...field} />}
            />
          </div>
        </div>

      </form>
    </Modal>
  );
};

export default SubTagModal;
