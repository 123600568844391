import { h, render, Component } from "preact";
import { Router, route } from "preact-router";
import Layout from "./layouts/Layout";
import ReportsIndex from "./pages/Reports";
import ReportsCreate from "./pages/Reports/Create";
import HeadersIndex from "./pages/Headers/";
import FiltersIndex from "./pages/Filters/";
import ComponentsIndex from "./pages/Components/";
import ModulesIndex from "./pages/Modules/";
import LanguagesIndex from "./pages/Languages/";
import LoginPage from "./pages/Login";
import WordsIndex from "./pages/Words/";
import StatsIndex from "./pages/Stats/";
import TagsIndex from './pages/Tags'
import SubTagsIndex from "./pages/SubTags";
import ParentReportIndex from "./pages/ParentReport";
import { useAuth } from "./auth";
import "./main.scss";

const App = () => {
  const [logged, user] = useAuth();

  window.route = route;

  const handleRoute = async (e) => {
    switch (e.url) {
      case "/login":
        if (logged) window.location = "/";
        break;
      default:
        if (!logged) window.location = "/login";
        break;
    }
  };

  return (
    <Router onChange={handleRoute}>
      <ReportsIndex path="/" />
      <ReportsCreate path="/reports/create" />
      <ReportsCreate path="/reports/:id" />
      <HeadersIndex path="/headers" />
      <FiltersIndex path="/filters" />
      <ComponentsIndex path="/components" />
      <ModulesIndex path="/modules" />
      <LanguagesIndex path="/languages" />
      <WordsIndex path="/words" />
      <LoginPage path="/login" />
      <StatsIndex path="/stats" />
      <TagsIndex path="/tags" />
      <SubTagsIndex path="/subtags" />
      <ParentReportIndex path="/parent-reports" />

    </Router>
  );
};

if (typeof window != "undefined") {
  render(<App />, document.body);
}
