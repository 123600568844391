import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useDebounce } from "../../hooks";
import Tooltip from "../../components/Tooltip";
import Layout from "../../layouts/Layout";
import Paginator from "../../components/Paginator";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../../auth";

const Stats = () => {
  const [data, setData] = useState({
    records: [],
    total: 0,
    per_page: 20,
  });
  const [query, setQuery] = useState("");
  const [page, setCurrentPage] = useState(1);
  const [activeModal, setActiveModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const debouncedSearchTerm = useDebounce(query, 500);
  const [clientId, setClientId] = useState("");
  const [reportName, setReportName] = useState("");


  const handleClose = () => {
    setActiveModal(false);
    setActiveId(null);
  };
  const fetchData = () => {
    fetch(`/api/stats/most-used-reports?client_id=${clientId}&report=${reportName}`)
      .then((resp) => {
        if (resp.ok) return resp.json();
        throw new Error("something went wrong while requesting posts");
      })
      .then((data) => setData(data));
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/clients/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id_client,
      label: el.client,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadReportsOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/reports/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.name,
      label: el.name,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [debouncedSearchTerm, clientId, reportName]);

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Layout breadcrumbs={["Idiomas"]}>
      <div className="columns is-multiline" style={{ margin: "1rem 0" }}>
        <div class="column is-12">
          <div class="card events-card">
            <header class="card-header">
              <p class="card-header-title">Reportes mas descargados</p>
              <a
                style={{ width: "40%" }}
                class="card-header-icon"
                aria-label="more options"
              >
                <AsyncPaginate
                  isClearable={true}
                  loadOptions={loadOptions}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  onChange={(val) => {
                    if (val != null) {
                      setClientId(val?.value);
                    } else {
                      setClientId("");
                    }
                  }}
                  placeholder="Filtrar por cliente"
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
                <AsyncPaginate
                  isClearable={true}
                  loadOptions={loadReportsOptions}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  onChange={(val) => {
                    if (val != null) {
                      setReportName(val?.value);
                    } else {
                      setReportName("");
                    }
                  }}
                  placeholder="Filtrar por reporte"
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                      marginLeft: '1rem'
                    }),
                  }}
                />
              </a>
            </header>
            <div class="card-table">
              <div class="content">
                <table class="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <td>Nombre</td>
                      <td>Cliente</td>
                      <td>Veces descargados</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.records.length ? (
                      data.records.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.client}</td>
                          <td>{item.cnt}</td>
                        </tr>
                      ))
                    ) : (
                      <div style="text-align: center;">
                        No hay datos para mostrar
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <div style={{ height: 40 }}></div>
            </footer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Stats;
