import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import { http as fetch } from "./../../auth";

const LanguageModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues } = useForm();
  const [data, setData] = useState("");
  const isAddMode = !id;

  useEffect(() => {
    reset();
    if (!isAddMode) {
      fetch("/api/languages/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          const fields = ["name", "key"];
          fields.forEach((field) => setValue(field, res[field]));
        });
    }
  }, [id]);

  const onSubmit = (data) => {
    const url = "";
    fetch(`/api/languages/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear idioma" : "Editar idioma"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Clave</label>
          <div class="control">
            <input
              {...register("key")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LanguageModal;
