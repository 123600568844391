import { h, component } from "preact";
import { useState, useEffect } from "preact/hooks";
import {
  useAsyncPaginate,
  useComponents,
  withAsyncPaginate,
} from "react-select-async-paginate";
import { http as fetch } from "./../auth";

const Select = ({ options, disabled = false, onChange, value }) => {
  return (
    <span class="select">
      <select value={value} onChange={onChange} disabled={disabled}>
        <option disabled selected value="">
          Seleccionar
        </option>
        {options.map((item) => (
          <option value={item.label}>{item.label}</option>
        ))}
      </select>
    </span>
  );
};
const Translations = (props) => {
  const { onChange, value: values = [] } = props;

  const [options, setOptions] = useState([]);
  const [langKey, setLang] = useState("");
  const [inputValue, setInputValue] = useState("");

  const recursiveSequential = async ({ page }) => {
    const json = await loadOptions({ page });
    setOptions((prev) => [...prev, ...json.options]);

    if (json.hasMore) {
      return recursiveSequential({ page: page + 1 });
    }
  };

  const loadOptions = async ({ page }) => {
    const response = await fetch(`/api/languages/?page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: `${el.key}`,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onAddLanguage = () => {
    if (langKey && inputValue && !values.find((el) => el.lang === langKey)) {
      const item = {
        lang: langKey,
        value: inputValue,
      };
      /* setData((prev) => [...prev, item]); */
      if (onChange instanceof Function) {
        onChange([...values, item]);
      }

      setLang("");
      setInputValue("");
    }
  };

  const handleDelete = (item) => {
    /* setData((prev) => prev.filter((el) => el.lang != item.lang)); */
    onChange(values.filter((el) => el.lang != item.lang));
  };

  useEffect(() => {
    recursiveSequential({ page: 1 });
  }, []);

  return (
    <div>
      <div class="field has-addons has-addons-centered">
        <p class="control">
          <Select
            value={langKey}
            onChange={(ev) => setLang(ev.target.value)}
            options={options.filter((i) => {
              if (values.some((e) => e.lang === i.label)) {
                return false;
              }
              return true;
            })}
          />
        </p>
        <p class="control">
          <input
            class="input"
            type="text"
            value={inputValue}
            onChange={(ev) => setInputValue(ev.target.value)}
          />
        </p>
        <p class="control">
          <a class="button is-link" onClick={onAddLanguage}>
            Agregar
          </a>
        </p>
      </div>
      {values.map((item, index) => (
        <div class="field has-addons has-addons-centered">
          <p class="control">
            <Select value={item.lang} disabled options={options} />
          </p>
          <p class="control">
            <input
              class="input"
              value={item.value}
              type="text"
              onChange={(ev) => {
                values[index].value = ev.target.value;
                onChange(values);
              }}
            />
          </p>
          <p class="control">
            <a
              class="button is-danger"
              onClick={() => {
                handleDelete(item);
              }}
            >
              Eliminar
            </a>
          </p>
        </div>
      ))}
    </div>
  );
};

export default Translations;
