import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../../auth";

const options = [
  { value: "date", label: "Date" },
  { value: "int", label: "Integer" },
  { value: "replace", label: "Replace" },
  { value: "string", label: "String" },
];

const FilterModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control } =
    useForm();
  const [data, setData] = useState("");
  const [componentsRecord, setComponents] = useState([]);
  const isAddMode = !id;

  useEffect(() => {
    reset({
      parameter_name: "",
      parameter_sql: "",
      display_type: "",
      component_id: "",
    });

    if (!isAddMode) {
      fetch("/api/filters/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          const fields = [
            "parameter_name",
            "parameter_sql",
            "display_type",
            "component_id",
          ];
          fields.forEach((field) => setValue(field, res[field]));
          if (res.component_id) {
            fetch("/api/components/" + res.component_id)
              .then((res) => {
                if (res.ok) return res.json();
                return Promise.reject(res);
              })
              .then((res) => {
                // setComponents((prev) => [{ label: res.name, value: res.id }]);
                setValue("component_id", { label: res.name, value: res.id });
              });
          }
        });
    }
  }, [id]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/components/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: el.name,
    }));

    setComponents((prev) => [...prev, options]);
    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (data) => {
    data.component_id = data.component_id?.value
      ? data.component_id.value
      : null;
    fetch(`/api/filters/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear filtro" : "Editar filtro"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("parameter_name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">SQL</label>
          <div class="control">
            <input
              {...register("parameter_sql")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="columns">
          <div class="field column is-6">
            <label class="label">Tipo de dato</label>
            <div class="control">
              <Controller
                name="display_type"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    inputRef={field.ref}
                    options={options}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    value={options.find((c) => c.value === field.value)}
                    onChange={(val) => field.onChange(val.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="field column is-6">
            <label class="label">Componente</label>
            <div class="control">
              <Controller
                name="component_id"
                control={control}
                render={({ field }) => (
                  <AsyncPaginate
                    inputRef={field.ref}
                    isClearable={true}
                    loadOptions={loadOptions}
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    {...field}
                    // value={
                    //   componentsRecord.find((c) => c.value === field.value) ||
                    //   null
                    // }
                    // onChange={(val) => field.onChange(val?.value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FilterModal;
