import { createElement, render } from "preact";
import { Link } from "preact-router/match";
import { logout } from "./../auth";

const Layout = ({ children, breadcrumbs = [], searchBar = null }) => (
  <div>
    <nav class="navbar is-link">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item brand-text" href="../index.html">
            Admin
          </a>
          <div class="navbar-burger burger" data-target="navMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenu" class="navbar-menu">
          <div class="navbar-start" style={{ margin: "0 auto" }}>
            <div class="navbar-item">
              <div class="control has-icons-left">
                <input
                  onInput={searchBar}
                  class="input"
                  type="search"
                  placeholder=""
                />
                <span class="icon is-left">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="columns">
        <div class="column is-2">
          <aside class="menu is-hidden-mobile">
            <p class="menu-label">Administracion</p>
            <ul class="menu-list">
              <li>
                <Link activeClassName="is-active" href="/">
                  Reportes
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/headers">
                  Cabeceras
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/filters">
                  Filtros
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/components">
                  Componentes
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/modules">
                  Modulos
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/stats">
                  Reportes mas descargados
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/tags">
                Tipos de etiquetas
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/subtags">
                  Etiquetas
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/parent-reports">
                  Padre de reportes
                </Link>
              </li>
            </ul>
            <p class="menu-label">Traducciones</p>
            <ul class="menu-list">
              <li>
                <Link activeClassName="is-active" href="/languages/">
                  Idiomas
                </Link>
              </li>
              <li>
                <Link activeClassName="is-active" href="/words/">
                  Palabras
                </Link>
              </li>
            </ul>
            <p class="menu-label">Usuario</p>
            <ul class="menu-list">
              <li
                onClick={() => {
                  logout();
                  window.location = "/login";
                }}
              >
                <a>Salir</a>
              </li>
            </ul>
          </aside>
        </div>
        <div class="column is-10">
          {/* <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active">
                <a href="">Administración</a>
              </li>
              {breadcrumbs.map((item) => (
                <li class="is-active">
                  <a href="">{item}</a>
                </li>
              ))}
            </ul>
          </nav> */}
          <div>{children}</div>
        </div>
      </div>
    </div>
  </div>
);
export default Layout;
