import { h, Component } from "preact";
import { usePagination, DOTS } from "../hooks";
import classnames from "classnames";

const Paginator = ({ total, page, setCurrentPage, pageSize }) => {
  const paginationRange = usePagination({
    currentPage: page,
    totalCount: total,
    siblingCount: 1,
    pageSize,
  });

  let lastPage = paginationRange[paginationRange.length - 1];

  const goToPreviousPage = () => {
    if (page > 1) return setCurrentPage((page) => page - 1);
  };
  const goToNextPage = () => {
    if (page < lastPage) return setCurrentPage((page) => page + 1);
  };
  const goToPage = (page) => setCurrentPage(page);

  return (
    <nav
      class="pagination is-small is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <a
        onClick={goToPreviousPage}
        class={classnames("pagination-previous", {
          "is-disabled": page === 1,
        })}
      >
        <span class="material-icons">chevron_left</span>
      </a>
      <a
        onClick={goToNextPage}
        class={classnames("pagination-next", {
          "is-disabled": page === lastPage,
        })}
      >
        <span class="material-icons">chevron_right</span>
      </a>
      <ul class="pagination-list">
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li>
                <span class="pagination-ellipsis">&hellip;</span>
              </li>
            );
          }
          return (
            <li>
              <a
                onClick={() => goToPage(pageNumber)}
                class={classnames("pagination-link", {
                  "is-current": pageNumber === page,
                })}
              >
                {pageNumber}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Paginator;
