import { h, render, Component } from "preact";
import Modal from "../../components/Modal";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "preact/hooks";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { http as fetch } from "./../../auth";
import Translations from "../../components/Translations";



const ParentReportModal = ({ activeModal, handleClose, reloadPage, id = null }) => {
  const { register, handleSubmit, reset, setValue, getValues, control } =
    useForm();
  const [data, setData] = useState("");
  const [componentsRecord, setComponents] = useState([]);
  const isAddMode = !id;

  useEffect(() => {
    reset({
      name: "",
      translations: [],
      icon: "",
      reports: []
    });

    if (!isAddMode) {
      fetch("/api/parent-reports/" + id)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          let data = {
            ...res
          }
          data.reports = data.reports.map(el => ({
            value: el.id,
            label: `${el.id}: ${el.name}`
          }))

          reset(data)
        });
    }
  }, [id]);

  const loadOptionsReports = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/reports/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id,
      label: `${el.id}: ${el.name}`,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (data) => {
    data.reports = data.reports ? data.reports.map(i => i.value) : []

    fetch(`/api/parent-reports/${isAddMode ? "" : id}`, {
      method: isAddMode ? "POST" : "PUT",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        reset();
        reloadPage();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      active={activeModal}
      onClose={handleClose}
      title={isAddMode ? "Crear reporte padre" : "Editar reporte padre"}
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div class="field">
          <label class="label">Nombre</label>
          <div class="control">
            <input
              {...register("name")}
              class="input"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field">
          <label class="label">Reportes a agrupar</label>
          <div className="control">
            <Controller
              name="reports"
              control={control}
              render={({ field }) => (
                <AsyncPaginate
                  inputRef={field.ref}
                  isMulti={true}
                  isClearable={true}
                  defaultOptions
                  placeholder="Seleccione"
                  loadOptions={loadOptionsReports}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Icon</label>
          <div class="control">
            <input
              {...register("icon")}
              class="input"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="field">
        <label class="label">Traducciones</label>

          <Controller
            name="translations"
            control={control}
            render={({ field }) => <Translations {...field} />}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ParentReportModal;
