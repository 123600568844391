import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useDebounce } from "../../hooks";
import Tooltip from "../../components/Tooltip";
import Layout from "../../layouts/Layout";
import Paginator from "../../components/Paginator";
import { http as fetch } from "./../../auth";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";

const Reports = () => {
  const [data, setData] = useState({
    records: [],
    total: 0,
    per_page: 20,
  });
  const [query, setQuery] = useState("");
  const [clientId, setClientId] = useState("");
  const [page, setCurrentPage] = useState(1);
  const debouncedSearchTerm = useDebounce(query, 500);

  const handleDelete = (id) => {
    let result = confirm("¿Estás seguro de querer eliminar este objeto? ");
    if (result) {
      fetch("/api/reports/" + id, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          fetchData();
        });
    }
  };

  const cloneReport = (id) => {
    let result = confirm("¿Estás seguro de querer clonar este objeto? ");
    if (result) {
      fetch(`/api/reports/${id}/clone`, {
        method: "POST",
      })
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          fetchData();
        });
    }
  };

  const fetchData = () => {
    fetch(`/api/reports?page=${page}&q=${query}&client_id=${clientId}`)
      .then((resp) => {
        if (resp.ok) return resp.json();
        throw new Error("something went wrong while requesting posts");
      })
      .then((data) => setData(data));
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await fetch(`/api/clients/?q=${search}&page=${page}`);
    const responseJSON = await response.json();
    const options = responseJSON.records.map((el) => ({
      value: el.id_client,
      label: el.client,
    }));

    return {
      options: options,
      hasMore: responseJSON.total_pages > responseJSON.page,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [debouncedSearchTerm, clientId]);

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <Layout
      breadcrumbs={["Reportes"]}
      searchBar={(e) => setQuery(e.target.value)}
    >
      <div className="columns is-multiline" style={{ margin: "1rem 0" }}>
        <div class="column is-12">
          <div class="card events-card">
            <header class="card-header">
              <p class="card-header-title">Reportes</p>
              <a
                style={{ width: "30%" }}
                class="card-header-icon"
                aria-label="more options"
              >
                <AsyncPaginate
                  isClearable={true}
                  loadOptions={loadOptions}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  onChange={(val) => {
                    if (val != null) {
                      setClientId(val?.value);

                    } else {
                      setClientId("")
                    }
                  }}
                  placeholder="Filtrar por"
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                />
              </a>
            </header>
            <div class="card-table">
              <div class="content">
                <table class="table is-fullwidth is-striped">
                  <tbody>
                    {data.records.length ? (
                      data.records.map((item) => (
                        <tr key={item.id}>
                          <td width="5%"></td>
                          <td>{item.id}</td>
                          <td>
                            <span class={!item.active ? "inactive-text" : ""}>
                              {item.name}
                            </span>
                          </td>
                          <td class="level-right actions">
                            {item.clients_allowed.length ? (
                              <a
                                href="#"
                                class="button is-small is-light is-primary"
                              >
                                <Tooltip
                                  label={
                                    "Permitidos: " +
                                    item.clients_allowed
                                      .map((el) =>
                                        el.name.replace("CLIENT_", "")
                                      )
                                      .join(", ")
                                  }
                                  className="custom-class"
                                />
                                <span class="material-icons">
                                  supervised_user_circle
                                </span>
                              </a>
                            ) : null}

                            {item.clients_not_allowed.length ? (
                              <a
                                href="#"
                                class="button is-small is-light is-danger"
                              >
                                <Tooltip
                                  label={
                                    "Ocultos: " +
                                    item.clients_not_allowed
                                      .map((el) =>
                                        el.name.replace("CLIENT_", "")
                                      )
                                      .join(", ")
                                  }
                                  className="custom-class"
                                />
                                <span class="material-icons">
                                  supervised_user_circle
                                </span>
                              </a>
                            ) : null}

                            <a
                              class="button is-small is-warning is-light"
                              onClick={() => cloneReport(item.id)}
                            >
                              <span class="material-icons">repeat</span>
                            </a>
                            <a
                              class="button is-small is-link"
                              href={"/reports/" + item.id}
                            >
                              <Tooltip
                                label="Editar"
                                className="custom-class"
                              />
                              <span class="material-icons">edit_note</span>
                            </a>
                            <a
                              class="button is-small is-danger"
                              onClick={() => handleDelete(item.id)}
                            >
                              <Tooltip
                                label="Eliminar"
                                className="custom-class"
                              />
                              <span class="material-icons">delete</span>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div style="text-align: center;">
                        No hay datos para mostrar
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <footer class="card-footer">
              <a href="/reports/create" class="card-footer-item">
                Crear reporte
              </a>
            </footer>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Paginas</p>
              <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </a>
            </header>
            <div class="card-content">
              <div class="">
                <Paginator
                  total={data.total}
                  page={page}
                  setCurrentPage={setCurrentPage}
                  pageSize={data.per_page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
