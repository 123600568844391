import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Modal from "../../components/Modal";
import Translations from "../../components/Translations";
import { http as fetch } from "./../../auth";

const ReportAssociatedModal = ({
  activeModal,
  handleClose,
  filterId = null,
}) => {
  const [items, setData] = useState([]);

  useEffect(() => {
    if (filterId) {
      fetch(`/api/filters/${filterId}/list-reports`)
        .then((res) => {
          if (res.ok) return res.json();
          return Promise.reject(res);
        })
        .then((res) => {
          setData(res);
        });
    }
  }, [filterId]);

  return (
    <Modal actions={false} active={activeModal} onClose={handleClose}>
      <div class="content">
        <h3>Reportes asociados:</h3>
        <ul>
          {items.map((el) => (
            <li>
              <a href={`/reports/${el.id}`}>ID: {el.id} </a>
              <span> {el.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default ReportAssociatedModal;
